.icon {
    font-family: 'Material Icons', serif;
    font-style: normal;
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}

/* VARIABLES */

:root {
    --main-color: #1a8fff;
    --text-color: #777;
    --text-color-light: #ccc;
    --border-color: #eee;
    --bg-color: #f9f9f9;
    --neutral-color: #fff;
}

/* GENERAL */

* {
    box-sizing: border-box;
}

header {
    display: block;
    width: 100%;
    padding: 1.75em 0;
    border-bottom: 1px solid var(--border-color);
    background: var(--neutral-color);
}

header #logo {
    font-size: 175%;
    text-align: center;
    color: var(--main-color);
    line-height: 1;
}

header #logo .icon {
    padding-right: 0.25em;
}

main {
    display: block;
    margin: 0 auto;
    margin-top: 5em;
    max-width: 50em;
}

/* GRID */

.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.row-middle {
    align-items: center;
}

.col {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
}

.col-start {
    justify-content: flex-start;
    text-align: left;
}

.col-center {
    justify-content: center;
    text-align: center;
}

.col-end {
    justify-content: flex-end;
    text-align: right;
}

/* Calendar */

.calendar-week {
    display: block;
    position: relative;
    width: 100%;
    background: var(--neutral-color);
}

.calendar-week .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid var(--border-color);
}

.calendar-week .header .icon {
    cursor: pointer;
    transition: 0.15s ease-out;
}

.calendar-week .header .icon:hover {
    /*transform: scale(1.75);*/
    transition: 0.25s ease-out;
    color: var(--main-color);
}

.calendar-week .header .icon:first-of-type {
    margin-left: 1em;
}

.calendar-week .header .icon:last-of-type {
    margin-right: 1em;
}

.calendar-week .days {
    text-transform: uppercase;
    font-weight: 700;
    line-height: 18px;
    color: #0b0b0b;
    font-size: 12px;
    padding: 0.75em 0;
}

.calendar-week .days .active {
    border-radius: 50%;
    background-color: #133f7d;
    color: #fff;
    width: 49px;
}

.calendar-week .body .cell {
    position: relative;
    height: 4em;
    border-right: 1px solid var(--border-color);
    overflow: hidden;
    cursor: pointer;
    background: var(--neutral-color);
    transition: 0.25s ease-out;
    font-size: 1.5em;
}

.calendar-week .body .cell:hover {
    background: var(--bg-color);
    transition: 0.5s ease-out;
}

.calendar-week .body .selected {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%);
    border-image-slice: 1;
}
.calendar-week .body .today {
    border-left: 10px solid transparent;
    border-image: linear-gradient(45deg, #ff1a79 0%, #eb82b3 40%);
    border-image-slice: 1;
}
.calendar-week .body .row {
    border-bottom: 1px solid var(--border-color);
}

/* .calendar .body .row:last-child {
    border-bottom: none;
  } */

.calendar-week .body .cell:last-child {
    border-right: none;
}

.calendar-week .body .cell .number {
    position: absolute;
    font-size: 82.5%;
    line-height: 1;
    top: 0.75em;
    right: 0.75em;
    font-weight: 700;
}

.calendar-week .body .disabled {
    color: var(--text-color-light);
    pointer-events: none;
}

.calendar-week .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: var(--main-color);
    opacity: 0;
    font-size: 8em;
    position: absolute;
    top: -0.2em;
    right: -0.05em;
    transition: 0.25s ease-out;
    letter-spacing: -0.07em;
}

.calendar-week .body .cell:hover .bg,
.calendar-week .body .selected .bg {
    opacity: 0.05;
    transition: 0.5s ease-in;
}
.calendar-week .body .cell.today .bg {
    color: #ff1a79;
    opacity: 0.05;
}

.calendar .body .col {
    flex-grow: 0;
    flex-basis: calc(100% / 7);
    width: calc(100% / 7);
}

.pad-5 {
    padding: 5px;
}

.top-30 {
    top: 30%;
}

.all-times {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    max-height: 207px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}
.all-times::-webkit-scrollbar {
    display: none;
}

.time-div {
    display: flex;
    padding: 8px 0px 7px 0px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 3px;
    border: 1px solid #cdcaca;
    color: #133f7d;
    cursor: pointer;
}

@media screen and (max-width: 990px) {
    .time-div {
        font-size: 8px;
    }
}

.time-div.active-time {
    background: #12b6bc;
    color: white;
}

.time-div-empty {
    display: flex;
    height: 35px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 3px;
    color: #cdcaca;
}

.gap-20 {
    gap: 20px;
}
