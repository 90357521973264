.main-logo-container2 {
    padding: 10px 20px;
    .company-logo {
        height: 40px;
        width: auto;
    }
}
.error-content-wrapper {
    .error-title,
    .error-subtitle {
        color: #123a75;
    }
    .error-img {
        width: 38%;
        @media screen and (max-width: 992px) {
            width: 80%;
        }
    }
    .home-btn {
        height: 2.8em;
        width: 12em;
        border: none;
        background-color: #123a75;
        color: #ffffff;
    }
}
