.thankyou-page {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;
    .clear-popup {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 40px;
        width: 320px;
        .clear-popup-title {
            font-size: 18px;
            text-align: center;
            color: #133f7d;
            margin-bottom: 25px;
        }
        .clear-popup-btn-container {
            .cpb {
                border: none;
                font-size: 14px;
                padding: 5px;
                color: white;
                width: 100px;
                margin: 0 10px;
            }
            .cpb-green {
                background-color: #626262;
            }
            .cpb-red {
                background-color: #f2464b;
            }
        }
    }
    .reviewpopup-container {
        position: fixed;
        height: 100vh;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .interviewer-schedules {
        background-color: #ffffff;
        width: 450px;
        height: 600px;
        position: relative;
        .close-review-tab {
            position: absolute;
            cursor: pointer;
            right: 0;
            padding: 9px;
            img {
                width: 18px;
            }
        }
        .interviewer-name {
            height: 40px;
            width: 100%;
            color: #ffffff;
            background-color: #123a75;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .ids-container {
            height: calc(100% - 100px);
            padding: 20px;
        }
        .interviewer-day-schedule {
            height: 100%;
            overflow-y: auto;
            .day-schedule {
                background-color: #f9fcfd;
                padding: 10px 20px !important;
            }
        }
    }
    .thankyou-btn-div {
        margin: 20px 0px;
    }
    .thankyou-content {
        height: 75%;
        width: 75%;
        flex-direction: column;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fff;
        position: relative;
        border-radius: 10px;
        .close-review-tab {
            cursor: pointer;
            position: absolute;
            height: 20px;
            width: 20px;
            top: 20px;
            right: 20px;
            border-radius: 50%;
            background-color: #133f7d;
            border: none;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                height: 10px;
                width: auto;
            }
        }

        .thankyou-bg {
            width: 25%;
            height: auto;
            margin-bottom: 25px;
        }
        .thankyou-title {
            font-style: normal;
            font-weight: 800;
            font-size: 30px;
            line-height: 68px;
            letter-spacing: 0.04em;
            margin: 10px 0px;
        }
        .thankyou-sub {
            text-align: center;
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            letter-spacing: 0.04em;
            .green-tick {
                height: 1.2em;
                width: auto;
                margin-right: 10px;
            }
        }
    }
}

.cpb-blue {
    background-color: #133f7d;
    color: white !important;
}

.popup-line {
    height: 1px;
    background-color: #5e6267;
    margin: 15px 0px;
}

.mar-15 {
    margin: 15px;
}

.reset-popup {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;
    padding: 20px;
    .reset-clear-popup {
        display: flex;
        justify-content: center;
        background-color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 24px;
        width: 400px;
        .close-btn {
            width: 12px;
            height: 12px;
            color: #667085;
            position: absolute;
            top: 24px;
            right: 24px;
        }
        .reset-popup-title {
            font-size: 18px;
            text-align: center;
            color: #133f7d;
            margin-bottom: 25px;
        }
        .reset-popup-btn-container {
            gap: 12px;
            .cpb {
                border: none;
                border-radius: 8px !important;
                font-size: 14px;
                padding: 32px, 0px, 0px, 0px;
                color: black;
                width: 100%;
                height: 44px;
            }
            .cpb-white {
                background-color: #ffffff;
                color: black;
                border-color: #d0d5dd;
                border: 1px solid;
            }
            .cpb-red {
                background-color: #d92d20;
                color: white;
            }
        }
    }
}
.mar-top-32 {
    margin-top: 32px;
}
.mar-top-16 {
    margin-top: 16px;
}
