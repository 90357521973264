body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
    height: 100vh;
    background-color: #f6f7fb;
    overflow: auto;
}

@font-face {
    font-family: 'Manrope-Bold';
    src: url('../fonts/Manrope/Manrope-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraBold';
    src: url('../fonts/Manrope/Manrope-ExtraBold.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-ExtraLight';
    src: url('../fonts/Manrope/Manrope-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Light';
    src: url('../fonts/Manrope/Manrope-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Medium';
    src: url('../fonts/Manrope/Manrope-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-Regular';
    src: url('../fonts/Manrope/Manrope-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Manrope-SemiBold';
    src: url('../fonts/Manrope/Manrope-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #123a75;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #123a75;
}

.f-mh {
    font-size: 58px;
}

.f-h {
    font-size: 38px;
}

.f-fh {
    font-size: 20px;
}

.f-sh {
    font-size: 16px;
}

.f-l {
    font-size: 12px;
}

.f-ph {
    font-size: 10px;
}

.f-c {
    font-size: 14px;
}

.f-s {
    font-size: 8px;
}

.f-xs {
    font-size: 6px;
}

.f-6 {
    font-size: 6px;
}

.f-8 {
    font-size: 8px;
}

.f-9 {
    font-size: 9px;
}

.f-10 {
    font-size: 10px;
}

.f-12 {
    font-size: 12px;
}

.f-13 {
    font-size: 13px;
}

.f-15 {
    font-size: 15px;
}

.f-14 {
    font-size: 14px;
}

.f-16 {
    font-size: 16px;
}

.f-18 {
    font-size: 18px;
}

.f-20 {
    font-size: 20px;
}

.f-22 {
    font-size: 22px;
}

.f-24 {
    font-size: 24px;
}

.f-26 {
    font-size: 26px;
}

.f-36 {
    font-size: 36px;
}

.f-40 {
    font-size: 40px;
}

.f-50 {
    font-size: 50px;
}

.f-60 {
    font-size: 60px;
}

.f-reg {
    font-family: 'Manrope-Regular';
}

.f-sem-bol {
    font-family: 'Manrope-SemiBold';
}

.f-med {
    font-family: 'Manrope-Medium';
}

.f-lig {
    font-family: 'Manrope-Light';
}

.f-ex-lig {
    font-family: 'Manrope-ExtraLight';
}

.f-ex-bol {
    font-family: 'Manrope-ExtraBold';
}

.f-bol {
    font-family: 'Manrope-Bold';
}

.fw-400 {
    font-weight: 400px !important;
}

.text-theme-peacock {
    color: #13b1b6;
}
.text-theme-blue {
    color: #123a75;
}

.d-center {
    display: flex;
    align-items: center;
    justify-content: center;
}
.react-calendar__navigation__label {
    background-color: white !important;
    color: black;
}
.tick-icon {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 9px;
    height: 9px;
}

.footer-text {
    color: #000;
    text-align: center;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.rotate-180 {
    transform: rotate(180deg);
}

.left-0 {
    left: 0px;
}

.right-0 {
    right: 0px;
}

.cursor-default {
    cursor: default;
}

.header {
    width: 100vw;
    height: 7vh;
    padding-left: 25px;
    display: flex;
    align-items: center;
    background-color: white;

    .image {
        height: 80%;
    }
}

.footer {
    width: 100vw;
    height: 7vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f8;
}

@media screen and (max-width: 768px) {
    .header {
        padding: 14px 25px;
        height: 8vh;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        .image {
            height: 100%;
        }
    }
    .header-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.thank-main-parent {
    width: 100vw;
    height: 86vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 21px;
}

.thank-main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 23px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.92px;
    color: #133f7d;
    text-align: center;
    @media screen and (max-width: 768px) {
        font-size: 14px;
    }

    .thank-you {
        color: #13b1b6;
        font-size: 42px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 1.68px;
        @media screen and (max-width: 768px) {
            font-size: 34px;
        }
    }
}

.thank-footer-text {
    color: #133f7d;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.72px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
}

.panelist-sync-load {
    width: 100vw;
    height: 93vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-body {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    @media screen and (max-width: 610px) {
        overflow-x: auto;
    }
}

.slots-main-body {
    height: 93%;
    width: 100%;
    min-width: 610px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #f5f5f8;
    gap: 15px;
    .back-body {
        width: 95%;
        padding: 10px 0px;
        color: #133f7d;
        font-size: 13.502px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
    .slots-head {
        width: 95%;
        display: flex;
        justify-content: space-between;
        padding: 0px 10px;
        .name-text {
            color: #0e1012;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 135.774%; /* 27.155px */
            letter-spacing: 0.2px;
        }
        .available-text {
            color: #0e1012;
            font-size: 29px;
            font-style: normal;
            font-weight: 700;
            line-height: 135%; /* 39.15px */
            letter-spacing: 0.29px;
        }
        .connected-info {
            display: flex;
            height: 31.72px;
            padding: 0px 4px;
            align-items: center;
            gap: 5px;
            border-radius: 5px;
            border: 1px solid #133f7d;
            color: #133f7d;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
    .slots-body {
        width: 95%;
        background-color: white;
        display: flex;
        padding: 40px 20px;
        gap: 15px;
        @media screen and (max-width: 768px) {
            width: 100%;
            flex-direction: column;
            gap: 0px;
            align-items: center;
            justify-content: center;
        }
    }
}

.wrapper {
    background: rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    align-items: center;
    justify-content: center;
    display: flex;

    .loader {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        border: 5px solid #133f7d;
        border-top-color: #ffffff;
        box-sizing: border-box;
        background: transparent;
        animation: loading 1s linear infinite;
        margin: auto;
    }

    @keyframes loading {
        0% {
            transform: rotate(0deg);
        }
        0% {
            transform: rotate(360deg);
        }
    }
}

.normalBtn {
    width: 50% !important;
    border: none !important;
    background-color: #133f7d !important;
    color: white !important;
}
