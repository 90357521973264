/* interviewer-calendar */
.main-logo-conatiner {
    padding: 10px 20px;
    background-color: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    .company-logo {
        height: 40px;
        width: auto;
    }
}
.calendar-box {
    height: calc(100% - 60px);
    overflow: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 992px) {
        display: block;
    }
}
.interviewer-calendar {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .calendar-page-title {
        font-size: 40px;
        font-weight: 600;
        text-align: center;
    }
}
.schedule-popup {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 25px;
    flex-direction: column;
    @media screen and (max-width: 992px) {
        height: auto;
    }
    .calendar-title {
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #0e1012;
        margin: 20px;
    }
    .calendar-slots-container {
        display: flex;
        // justify-content: space-between;
        height: auto;
        @media screen and (max-width: 992px) {
            margin: 40px 0;
            flex-direction: column;
        }
        .calendar-part {
            // margin: 0 auto;
            // width: 64%;
            margin-right: 40px;
            background-color: #1c3a75;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 992px) {
                margin-right: 0;
                flex-direction: column;
            }
            .calendar-btn-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 10px;
                .cal-btn {
                    height: 2.5em;
                    width: 9em;
                    border-radius: 0.4em;
                    margin: 10px;
                    background-color: transparent;
                }
                .outline {
                    border: 1.5px solid #becada;
                    color: #123a75;
                    background-color: #ffffff;
                }
                .light-blue {
                    border: none;
                    background-color: #123a75;
                    color: #ffffff;
                    border: 2px solid #fff;
                }
            }
        }
        .calendar-candidate-part {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background-color: #1c3a75;
            padding: 20px;
            @media screen and (max-width: 992px) {
                flex-direction: column;
            }
            .calendar-btn-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .cal-btn {
                    height: 2.5em;
                    width: 9em;
                    border-radius: 0.4em;
                    margin: 10px;
                    background-color: transparent;
                }
                .outline {
                    border: 1.5px solid #becada;
                    color: #123a75;
                    background-color: #ffffff;
                }
                .light-blue {
                    border: none;
                    background-color: #123a75;
                    color: #ffffff;
                }
            }
        }

        .common-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #123a75;
            border: 1.5px solid #123a75;
            color: #ffffff;
            border-radius: 0.5em;
        }

        .outline-btn {
            border: 1.5px solid #123a75;
            color: #123a75;
            padding: 0.6em 2em;
            border-radius: 0.5em;
            box-sizing: border-box;
            padding: 8px 12px;
            gap: 10px;
            width: 66px;
            height: 35px;
            background: #ffffff;
            border: 1px solid #133f7d;
            border-radius: 5px;
            font-size: 14px;
        }

        .d-center {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .schedule-btn-container {
            display: flex;
            justify-content: center;
            padding: 10px 0px;
            .common-btn,
            .outline-btn {
                padding: 0.5em 1em;
                border-radius: 0.4em;
            }
            .common-btn {
                margin-right: 10px;
            }
            .outline-btn {
                background-color: #ffffff;
            }
        }
    }
    .reviewpopup-container {
        position: fixed;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .interviewer-error-schedules {
        width: 500px;
        background-color: #ffffff;
        height: 420px;
        padding: 10px;
        text-align: center;
        @media screen and (max-width: 600px) {
            width: 300px;
        }
        .ids-container {
            text-align: left;
            height: calc(100% - 100px);
            padding: 20px;
        }
        .interviewer-day-schedule {
            height: 100%;
            overflow-y: auto;
            .day-schedule {
                background-color: #f9fcfd;
                padding: 10px 20px !important;
            }
        }
    }
    .interviewer-schedules {
        width: 350px;
        background-color: #ffffff;
        height: 420px;
        @media screen and (max-width: 992px) {
            margin: 20px auto 0 auto;
        }
        @media screen and (max-width: 600px) {
            width: 320px;
        }
        .interviewer-name {
            height: 40px;
            color: #ffffff;
            background-color: #123a75;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .ids-container {
            height: calc(100% - 100px);
            padding: 20px;
        }
        .interviewer-day-schedule {
            height: 100%;
            overflow-y: auto;
            .day-schedule {
                background-color: #f9fcfd;
                padding: 10px 20px !important;
            }
        }
    }
}

.panelist-slot-schedule {
    width: 100%;
    height: 93%;
    display: flex;
    align-items: center;
    padding-top: 5px;
    flex-direction: column;
    background-color: #f5f5f8;
    @media screen and (max-width: 992px) {
        height: 92%;
    }
    @media screen and (max-width: 600px) {
        height: 100%;
    }
    .panelist-slots-title-center {
        align-items: center;
        justify-content: center;
        @media screen and (max-width: 768px) {
            margin-top: 40px;
        }
    }
    .calendar-title {
        font-style: normal;
        font-weight: 700;
        line-height: 135%;
        text-align: center;
        letter-spacing: 0.01em;
        color: #0e1012;
        margin: 10px;
    }
    .calendar-slots-container {
        display: flex;
        width: 550px;
        // justify-content: space-between;
        height: auto;
        @media screen and (max-width: 600px) {
            width: 100%;
            height: auto;
        }
        @media screen and (max-width: 992px) {
            // margin: 40px 0;
            flex-direction: column;
        }
        .save-reset-btn-div {
            padding: 20px 20px;
            border: none;
            justify-content: center !important;
            display: flex;
            gap: 20px;
            width: 550px;
            height: auto;
            background-color: #ffffff;
            box-shadow: 0px -3px 16.5px 0px rgba(0, 0, 0, 0.09);
            @media screen and (max-width: 600px) {
                width: 100%;
            }
        }
        .select-days-drop {
            width: 160px;
            @media screen and (max-width: 600px) {
                width: calc(100% - 10px);
            }
            padding: 5px;
            margin-right: 10px;
            margin-top: 10px;
        }
        .p-0 {
            padding: 0px;
        }
        .select-office-drop {
            width: 71px;
            @media screen and (max-width: 600px) {
                width: calc(100% - 10px);
            }
            padding: 5px;
            margin-right: 5px;
            margin-top: 10px;
        }
        .select-office-drop-end {
            width: 71px;
            @media screen and (max-width: 600px) {
                width: calc(100% - 10px);
            }
            padding: 5px;
            margin-right: 10px;
            margin-top: 10px;
        }
        .select-duration-drop {
            width: 160px;
            @media screen and (max-width: 600px) {
                width: calc(100% - 10px);
            }
            padding: 5px;
            margin-right: 5px;
            margin-top: 10px;
        }

        .hr-style {
            margin: 5px 0px;
        }
        .calendar-part {
            // margin: 0 auto;
            // width: 64%;
            margin-right: 40px;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media screen and (max-width: 992px) {
                margin-right: 0;
                flex-direction: column;
            }
            .calendar-btn-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 10px;
                .cal-btn {
                    height: 2.5em;
                    width: 9em;
                    border-radius: 0.4em;
                    margin: 10px;
                    background-color: transparent;
                }
                .outline {
                    border: 1.5px solid #becada;
                    color: #123a75;
                    background-color: #ffffff;
                }
                .light-blue {
                    border: none;
                    background-color: #123a75;
                    color: #ffffff;
                    border: 2px solid #fff;
                }
            }
        }
        .calendar-candidate-part {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            background-color: #1c3a75;
            padding: 20px;
            @media screen and (max-width: 992px) {
                flex-direction: column;
            }
            .calendar-btn-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .cal-btn {
                    height: 2.5em;
                    width: 9em;
                    border-radius: 0.4em;
                    margin: 10px;
                    background-color: transparent;
                }
                .outline {
                    border: 1.5px solid #becada;
                    color: #123a75;
                    background-color: #ffffff;
                }
                .light-blue {
                    border: none;
                    background-color: #123a75;
                    color: #ffffff;
                }
            }
        }

        .common-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #123a75;
            border: 1.5px solid #123a75;
            color: #ffffff;
            border-radius: 0.5em;
        }

        .outline-btn {
            border: 1.5px solid #123a75;
            color: #123a75;
            padding: 0.6em 2em;
            border-radius: 0.5em;
            box-sizing: border-box;
            padding: 8px 12px;
            gap: 10px;
            width: 66px;
            height: 35px;
            background: #ffffff;
            border: 1px solid #133f7d;
            border-radius: 5px;
            font-size: 14px;
        }

        .d-center {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .schedule-btn-container {
            display: flex;
            justify-content: center;
            padding: 10px 0px;
            .common-btn,
            .outline-btn {
                padding: 0.5em 1em;
                border-radius: 0.4em;
            }
            .common-btn {
                margin-right: 10px;
            }
            .outline-btn {
                background-color: #ffffff;
            }
        }
    }
    .reviewpopup-container {
        position: fixed;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.15);
    }
    .interviewer-error-schedules {
        width: 500px;
        background-color: #ffffff;
        height: 420px;
        padding: 10px;
        text-align: center;
        @media screen and (max-width: 600px) {
            width: 300px;
        }
        .ids-container {
            text-align: left;
            height: calc(100% - 100px);
            padding: 20px;
        }
        .interviewer-day-schedule {
            height: 100%;
            overflow-y: auto;
            .day-schedule {
                background-color: #f9fcfd;
                padding: 10px 20px !important;
            }
        }
    }
    .interviewer-schedules {
        width: 350px;
        background-color: #ffffff;
        height: 420px;
        @media screen and (max-width: 992px) {
            margin: 20px auto 0 auto;
        }
        @media screen and (max-width: 600px) {
            width: 320px;
        }
        .interviewer-name {
            height: 40px;
            color: #ffffff;
            background-color: #123a75;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        .ids-container {
            height: calc(100% - 100px);
            padding: 20px;
        }
        .interviewer-day-schedule {
            height: 100%;
            overflow-y: auto;
            .day-schedule {
                background-color: #f9fcfd;
                padding: 10px 20px !important;
            }
        }
    }
}

.flex-wrap-600 {
    @media screen and (max-width: 600px) {
        flex-wrap: wrap;
    }
}

.days-timing-dur {
    width: 100%;
}

.days {
    @media screen and (max-width: 600px) {
        width: 100%;
    }
}
.timing {
    @media screen and (max-width: 600px) {
        width: 100%;
    }
}
.duration {
    @media screen and (max-width: 600px) {
        width: calc(100% - 10px);
    }
}
.f-text-24 {
    font-size: 24px;
    @media screen and (max-width: 600px) {
        font-size: 18px;
    }
}
.f-text-18 {
    font-size: 18px;
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
}
.mtop-10 {
    margin-top: 10px;
}
.mtop-5 {
    margin-top: 5px;
}
.mtop-1 {
    margin-top: 1%;
}
.text-to {
    margin-top: 12px;
    margin-right: 5px;
}
.addDays {
    margin-top: 10px;
    color: #0d6efd;
    font-weight: 600;
}
.text-calendar {
    font-weight: 600;
    line-height: 20px;
}
.asterisk {
    color: red;
    margin-left: 5px;
}
.cursor-pointer {
    cursor: pointer;
}
.DateRangePicker__Date {
    width: 40px !important;
    height: 40px !important;
    padding: 2px !important;
}

.DateRangePicker__CalendarSelection--start,
.DateRangePicker__CalendarSelection--end,
.DateRangePicker__Date--is-selected,
.DateRangePicker__Date--is-highlighted {
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
    border-radius: 50% !important;
    background-color: #123a75 !important;
    font-weight: 500 !important;
    color: #fff !important;
}

.DateRangePicker__CalendarSelection,
.DateRangePicker__CalendarHighlight {
    border: none !important;
    background-color: #123a75 !important;
    right: 0px !important;
    top: 0px !important;
    left: 0px !important;
    bottom: 0px !important;
}

.DateRangePicker {
    display: block !important;
    text-align: center !important;
}

.calendar {
    //   height: 100%;
    //   width: 55%;
    margin: 2%;
    @media screen and (max-width: 600px) {
        width: 100%;
    }
}

.calendar-candidate {
    // height: 100%;
    // width: 55%;
    margin: 10px;
    // border: 2px solid green;
}

// @media only screen and (max-width: 1099px) {
//   .calendar-candidate {
//     height: 100%;
//     width: 96%;
//     margin: 2%;
//   }
// }

// @media only screen and (min-width: 1100px) {
//   .calendar-candidate {
//     height: 100%;
//     width: 55%;
//     margin: 2%;
//   }
// }

.calendar-container {
    height: calc(100% - 50px);
    background-color: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.calendar-container-new {
    width: 550px;
    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        max-height: none;
    }
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    background-color: #ffffff;
    padding: 20px 20px 20px 20px;
}
.error-border {
    border-color: red !important;
    border: 1px solid;
    border-radius: 5px;
}

.slot-container {
    height: calc(100% - 50px);
    background-color: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.calendar-candidate-container {
    // height: 100%;
    background-color: #ffffff;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
    padding: 20px 40px;
    @media screen and (max-width: 600px) {
        padding: 20px;
    }
}
.calander-timeslots-container {
    padding: 20px;
    background-color: #ffffff;
    height: 353px;
    width: 325px;
    margin: 10px;
    text-align: center;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);

    @media screen and (max-width: 992px) {
        width: calc(100% - 20px);
        height: 340px;
    }

    .time-slots-list {
        height: calc(100% - 160px);
        overflow-y: auto;
        margin: 10px 0;
        text-align: center;
    }
    .time-slots-list-candidate {
        height: calc(100% - 100px);
        overflow-y: auto;
        margin: 10px 0;
        text-align: center;
    }
    .time-drop {
        background-color: #f5f7ff;
        height: 25px;
        border: none;
        outline: none;
        color: #8780ab;
        padding: 5px;
        height: 30px;
        width: 100px;
    }
    .start-time {
        background-color: #f5f7ff;
        height: 30px;
        border: none;
        outline: none;
        color: #8780ab;
        padding: 5px;
    }
}

.divider {
    height: 100%;
    width: 1px;
    background-color: #000000;
}

.calendar-buttons {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    justify-content: center !important;
    display: flex;
    gap: 5px;
}

.add-slot-calendar {
    position: relative;
    border: none;
    color: #ffffff;
    padding: 6px 12px;
    gap: 10px;
    width: 127px;
    height: 40px;
    background: #172b4d;
    border-radius: 5px;
    font-weight: 600;
}
.isgoogle-microsoft {
    background: #18ac00;
}
.save-btn {
    border: none;
    color: #ffffff;
    padding: 6px 12px;
    gap: 10px;
    width: 120px !important;
    height: 35px;
    background: #133f7d;
    border-radius: 5px;
}
.reset-btn {
    border: solid;
    color: #133f7d;
    padding: 6px 12px;
    gap: 10px;
    width: 120px !important;
    height: 35px;
    background: #ffffff;
    border-radius: 5px;
}
.add-days-btn {
    background: none;
    border: none;
    color: #0d6efd;
}

.disabled {
    color: #626262;
}

.add-slot {
    border: none;
    color: #ffffff;
    padding: 6px 12px;
    gap: 10px;
    width: 47px;
    height: 28px;
    background: #133f7d;
    border-radius: 5px;
}

.time-slot-range {
    border: 1px solid #becada;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: 29px;
    padding: 1px 10px;
    .remove-time-slot {
        border-radius: 50%;
        background-color: #123a75;
        cursor: pointer;
    }
}

.tsr-w-1 {
    width: 170px;
    margin: 7px auto 0 auto;
    cursor: pointer;
    .remove-time-slot {
        height: 12px;
        width: 12px;
        .white-close {
            height: 6px;
        }
    }
}
.tsr-w-2 {
    width: 170px;
    .remove-time-slot {
        height: 12px;
        width: 12px;
        .white-close {
            height: 6px;
        }
    }
}
.mar-7 {
    margin-top: 7%;
}
.rmdp-wrapper {
    background-color: transparent !important;
}

.rmdp-border-right {
    border-right: none !important;
}

.rmdp-day {
    color: #133f7d !important;
    font-weight: 500;
}

.rmdp-day.rmdp-today span,
.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #123a75 !important;
    color: #fff !important;
}

.rmdp-panel-body li,
.rmdp-panel-body li.bg-blue {
    border: 1.2px solid #626262 !important;
    color: #000000 !important;
    background-color: #ffffff !important;
    box-shadow: none !important;
}

.rmdp-week-day {
    color: #000 !important;
}

.panelist-title-center {
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 992px) {
        width: 80%;
        margin-top: 40px;
    }
}

.rmdp-panel-body span {
    font-size: 11px !important;
    font-weight: 100;
    line-height: 20px !important;
    font-family: 'Manrope-Regular';
}

.rmdp-panel {
    min-width: 100px !important;
}

.rmdp-shadow {
    box-shadow: none !important;
    border: none !important;
    display: block;
}

.slot-title {
    font-size: 12px;
    text-align: left !important;
    padding: 2px;
}

.slot-btn {
    line-height: 1.5;
    padding: 0.2rem 0.4rem;
    border-radius: 0.3rem;
    position: relative;
    transition: all 0.15s ease;
    letter-spacing: 0.025em;
    text-transform: none;
    will-change: transform;
    font-size: 0.6rem;
}

.time-slot-div {
    border-radius: 3px;
    box-shadow: 0 0 2px #8798ad;
    color: #fff;
    display: flex;
    margin: 5px;
    background-color: #123a75;
    position: relative;
    width: 100px;
    font-size: 12px;
}

.date-slot-div {
    background-color: #fff;
    color: #123a75;
    border-left: 2px solid #123a75;
    text-align: center;
    border-radius: 3px;
    position: relative;
    width: 120px;
    padding: 3px;
    box-shadow: 0 0 2px #8798ad;
    font-size: 12px;
    margin: 5px;
}

.time-slot-span {
    padding: 3px 5px;
}

.review-slot-deselect {
    align-items: center;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: inline-flex;
    font-family: Arial !important;
    font-size: 16px;
    height: 12px;
    justify-content: center;
    line-height: 5px;
    padding: 0;
    position: absolute;
    right: 5px;
    top: 52%;
    transform: translateY(-50%) rotate(45deg);
    width: 12px;
}

.slot-mbtn {
    margin-bottom: 12px;
    display: flex;
}

.overflow-yes {
    overflow: auto;
}

.top-toggle {
    top: 2px;
    margin: 0px 5px;
}

.date-time-slot-div {
    max-height: 360px;
    overflow: auto;
}

.rmdp-day span {
    &:hover {
        background-color: #626262 !important;
        color: #ffffff !important;
    }
}
.rmdp-day.rmdp-disabled {
    color: #d8e5f8 !important;
}

.rmdp-day,
.rmdp-week-day {
    height: 40px !important;
    width: 40px !important;
}
/* interviewer-calendar */

.error-message {
    text-align: center;
    width: 100%;
    margin: 10px 0px;
    display: block;
    color: red;
}

.error-message-new {
    display: block;
    position: absolute;
    color: red;
    right: 8px;
    bottom: -18px;
    font-weight: 600;
}

.error-message-last {
    display: block;
    position: absolute;
    color: red;
    right: 8px;
    bottom: -30px;
    font-weight: 600;
}

.calendar-date {
    font-style: normal;
    font-size: 16px;
    line-height: 132%;
    letter-spacing: 0.01em;
    font-feature-settings: 'liga' off;
}

.calendar-dateformat {
    font-style: normal;
    font-size: 16px;
    line-height: 132%;
    letter-spacing: 0.01em;
    font-feature-settings: 'liga' off;
}

.calendar-time-div {
    padding: 5px 0px;
}

.from-time,
.to-time {
    color: #626262;
    font-size: 16px;
}

.rounded-10 {
    border-radius: 10px;
}

.save-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px !important;
    gap: 10px;
    width: 134px;
    height: 35px;
    background: #133f7d;
    border-radius: 5px;
    font-size: 14px;
    color: #ffffff !important;
    border: none;
}

.rmdp-week-day {
    color: #133f70 !important;
    font-size: 16px !important;
}

.rmdp-header-values {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
}

.rmdp-day span {
    font-size: 16px !important;
}

.react-calendar {
    border: none !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    position: relative;
    color: #133f70 !important;
    font-size: 16px !important;
    cursor: default;
    text-decoration: none !important;
    border: none;
    background: white;
    font-family: 'Manrope-Bold';
    text-transform: capitalize;
    @media screen and (max-width: 600px) {
        font-size: 14px !important;
    }
}

.react-calendar__tile--active {
    box-shadow: 0 0 3px #8798ad;
    color: #fff !important;
    border-radius: 50%;
    bottom: 3px;
    font-size: 14px;
    left: 3px;
    right: 3px;
    top: 3px;
}

.react-calendar__tile:disabled {
    color: #9f9f9f !important;
}
.react-calendar__tile:not(:disabled) {
    color: #000;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    height: 40px;
}
.react-calendar__month-view__weekdays__weekday {
    flex: inherit !important;
    margin: 0px 2.5px;
}
.react-calendar__tile:disabled,
.react-calendar__tile--now,
.react-calendar__tile {
    background-color: #f0f0f0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    height: 40px;
    flex: inherit !important;
    padding: 0px !important;
    border: none;
    margin: 0px 2.5px !important;
    abbr {
        margin: 0 auto;
    }
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
    display: none !important;
}

.react-calendar__navigation__label__labelText {
    font-size: 24px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Manrope-Bold';
}

.react-calendar__tile:disabled {
    background-color: #fff !important;
}
.react-calendar__month-view__days abbr {
    font-size: 16px !important;
    font-family: 'Manrope-Bold';
}
.react-calendar__tile--active abbr {
    color: #fff !important;
}

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    border: 1px solid #a0a096;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    @media screen and (max-width: 600px) {
        width: 300px;
    }
}
.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    justify-content: center;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    justify-content: center;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
}
.react-calendar__tile:disabled {
    background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}

.calendar-view {
    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        background-color: transparent;
    }
    .react-calendar {
        pointer-events: none;
    }
}

.slot-view {
    .react-calendar {
        pointer-events: none;
    }
}

.react-calendar__tile--active {
    background: #123a75 !important;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #123a75;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.slot-active {
    background: #123a75;
    span {
        color: white !important;
    }
}

.img-background {
    width: 100%;
    position: absolute;
    z-index: -1;
    height: 100vh;
    opacity: 0.5;
}

.cld-unavailable {
    background: #d0d0d8;
    border: 1.5px solid #d0d0d8 !important;
}

.rmdp-day.rmdp-today span {
    background-color: #ffffff !important;
    color: #000000 !important;
}

.error-popup-div {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 10000;
    align-items: center;
    justify-content: center;
    display: flex;
    overflow-y: auto;
}

.new-candidate-slots {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f8;
    overflow: hidden;
    .main-parent {
        width: 100vw;
        height: 86vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .main-div {
        max-height: 95%;
        width: 90%;
        padding: 20px 30px;
        background: #fff;
        overflow-y: auto;
        @media screen and (max-width: 768px) {
            height: 100%;
            width: 100%;
            min-width: 380px;
        }
    }
    .main-body {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
        .candidate-info {
            display: flex;
            align-items: flex-start;
            gap: 90px;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 0px;
                align-items: center;
                justify-content: center;
            }
            .candidate-name {
                color: #0e1012;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: 146%; /* 29.2px */
                letter-spacing: 0.2px;
                // width: 30%;
                text-wrap: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 300px;
                @media screen and (max-width: 768px) {
                    text-align: center;
                }
            }
            .interview-schedule {
                display: flex;
                width: 100%;
                flex-direction: column;
                align-items: flex-start;
                gap: 14px;
                @media screen and (max-width: 768px) {
                    align-items: center;
                    justify-content: center;
                    gap: 5px;
                }
                .title {
                    color: #0e1012;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 135%; /* 32.4px */
                    letter-spacing: 0.24px;
                    text-wrap: nowrap;
                    @media screen and (max-width: 768px) {
                        font-size: 16px;
                    }
                }
                .schedule-body {
                    display: flex;
                    align-items: flex-start;
                    gap: 62px;
                    align-self: stretch;
                    @media screen and (max-width: 1200px) {
                        gap: 40px;
                    }
                    @media screen and (max-width: 990px) {
                        gap: 10px;
                    }
                    @media screen and (max-width: 768px) {
                        flex-direction: column;
                        gap: 5px;
                        align-items: center;
                        justify-content: center;
                    }
                    .interview-duration {
                        display: flex;
                        width: 215px;
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 14px;
                        @media screen and (max-width: 768px) {
                            flex-direction: row;
                            gap: 20px;
                            align-items: center;
                            justify-content: space-between;
                        }
                        .duration-info {
                            display: flex;
                            align-items: center;
                            gap: 15px;
                            align-self: stretch;
                            color: #0e1012;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 700;
                            line-height: 146%; /* 23.36px */
                            letter-spacing: 0.16px;
                            text-wrap: nowrap;
                            @media screen and (max-width: 1200px) {
                                font-size: 12px;
                            }
                            @media screen and (max-width: 990px) {
                                font-size: 10px;
                            }
                        }
                    }
                    .congratulation {
                        color: #0e1012;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 146%; /* 20.44px */
                        letter-spacing: 0.14px;
                        @media screen and (max-width: 1200px) {
                            font-size: 12px;
                        }
                        @media screen and (max-width: 990px) {
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .calendar-view {
            display: flex;
            flex-direction: row;
            padding: 12px;
            align-items: flex-start;
            gap: 14px;
            align-self: stretch;
            border-radius: 5px;
            border: 0.5px solid #cdcaca;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                gap: 5px;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .foot-buttons {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        gap: 20px;

        .btn {
            display: flex;
            padding: 5px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            background: white;
            color: white;
        }
        .btn.save {
            background: #133f7d;
        }
        .btn.disable {
            background: #626262;
            cursor: default;
        }
        .btn.cancel {
            border: 1px solid #133f7d;
            color: #133f7d;
        }
    }
}

.date-with-slots {
    background-color: #b3c2d5;
    // border: 1px solid #B3C2D5 !important;
    color: white !important;
    border-radius: 50%;
}

.color-boxes {
    width: 15.556px;
    height: 15.556px;
    background-color: #133f7d;
}

.color-boxes.blue {
    background-color: rgba(19, 63, 125, 0.3);
}

.gap-30 {
    gap: 30px;
}

.cancel-page {
    height: 100vh;
    width: 100vw;
    background-color: #f5f5f8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .cancel-head {
        width: 100vw;
        text-align: center;
        padding: 20px 0px;
        color: #0e1012;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
    }
    .cancel-body {
        background-color: white;
        box-shadow:
            6px 4px 4px 0px rgba(0, 0, 0, 0.05),
            -4px 4px 4px 0px rgba(0, 0, 0, 0.05),
            0px -4px 4px 0px rgba(0, 0, 0, 0.05),
            0px 4px 4px 0px rgba(0, 0, 0, 0.05);
        padding: 25px;
        width: 75%;
        .cancel-body-head {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
        }
        .cancel-body-sub-head {
            display: flex;
            gap: 15px;
            color: #4f4f4f;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
        }
        .divider {
            height: 0.5px;
            background-color: #b6b6b6;
            width: 100%;
            margin: 25px 0px;
        }
        .post-divider-head {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            .red {
                color: #f00;
            }
        }
        .post-divider-head.mb-15 {
            margin-bottom: 15px;
        }
        .cancel-reason {
            border-radius: 5px;
            border: 1px solid #b6b6b6;
            height: 120px;
            width: 60%;
            margin: 7px 0px;
            resize: none;
            font-size: 13px;
        }
        .cancel-btn {
            display: flex;
            padding: 8px 31px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 5px;
            background: #133f7d;
            color: white;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.calendar-new-head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    color: #0e1012;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    @media screen and (max-width: 990px) {
        font-size: 10px;
    }
}

.w-100 {
    width: 100%;
}

.thank-you-body {
    max-width: 900px;
    word-wrap: break-word;
    display: block;
}
